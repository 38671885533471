var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-alert',{staticClass:"alert alert-custom alert-white alert-shadow fade show gutter-b",attrs:{"show":"","variant":"light"}},[_c('div',{staticClass:"alert-icon"},[_c('span',{staticClass:"svg-icon svg-icon-lg"},[_c('inline-svg',{attrs:{"src":"/media/svg/icons/Tools/Compass.svg"}})],1)]),_c('div',{staticClass:"alert-text"},[_vm._v(" Daftar "),_c('b',[_vm._v("Status Perjanjian")]),_vm._v(" yang ada di Klinik Hayandra ")]),(_vm.btn)?_c('b-button',{attrs:{"squared":"","variant":"success"},on:{"click":function($event){return _vm.$router.push('/masters/appointment-statuses/add')}}},[_vm._v("Tambah")]):_vm._e()],1),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('Card',{scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('div',{staticClass:"row justify-content-end mt-n3"},[_c('div',{staticClass:"col-md-3"},[_c('b-input-group',{scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('b-input-group-text',{attrs:{"squared":""}},[_c('b-icon-search')],1)]},proxy:true}])},[_c('b-form-input',{attrs:{"type":"text","placeholder":"Cari Nama Status"},on:{"keyup":_vm.filterByName},model:{value:(_vm.filter.name),callback:function ($$v) {_vm.$set(_vm.filter, "name", $$v)},expression:"filter.name"}})],1)],1)]),_c('b-table',{staticClass:"mt-3",style:('white-space: nowrap'),attrs:{"striped":"","hover":"","items":_vm.items,"fields":_vm.fields,"responsive":"sm"},scopedSlots:_vm._u([{key:"table-colgroup",fn:function(scope){return _vm._l((scope.fields),function(field){return _c('col',{key:field.key,style:({ width: field.key === 'actions' ? '15%' : '' })})})}},{key:"cell(actions)",fn:function(data){return [_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"mr-1 btn-info",attrs:{"size":"sm","title":"Detail"},on:{"click":function($event){return _vm.$router.push({
                    path:
                      '/masters/appointment-statuses/detail/' + data.item.id,
                  })}}},[_c('i',{staticClass:"fas fa-eye px-0"})]),(_vm.btn)?_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"mr-1 btn-success",attrs:{"size":"sm","title":"Edit"},on:{"click":function($event){return _vm.$router.push({
                    path:
                      '/masters/appointment-statuses/edit/' + data.item.id,
                  })}}},[_c('i',{staticClass:"fas fa-edit px-0"})]):_vm._e(),(data.item.id > 5 && _vm.btn == true)?_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"btn-danger",attrs:{"size":"sm","title":"Hapus"},on:{"click":function($event){return _vm.deleteData(data.item.id)}}},[_c('i',{staticClass:"fas fa-trash px-0"})]):_vm._e()]}}])}),(_vm.items.length != 0)?_c('b-pagination',{staticClass:"mt-4",attrs:{"total-rows":_vm.totalRows,"per-page":_vm.perPage},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}}):_vm._e()]},proxy:true}])})],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }